<template>
  <div
    itemscope
    itemtype="http://schema.org/Product"
    class="hidden"
  >
    <span itemprop="name">
      {{ product.name | htmlDecode }}
    </span>
    <span itemprop="description">
      {{ product.description | htmlDecode }}
    </span>
    <meta itemprop="gtin13" :content="product.gtin">
    <span itemprop="sku" :content="product.sku">
      {{ $t("SKU") }}: {{ product.parentSku }}
    </span>
    <meta itemprop="brand" :content="getBrand">
    <meta
      v-if="gallery && gallery.length"
      itemprop="image"
      :content="gallery[0].src"
    >
    <meta itemprop="sku" :content="product.sku">
    <div
      v-if="reviewArr && reviewArr.length > 0"
      itemprop="review"
      itemscope
      itemtype="http://schema.org/Review"
      class="hidden"
    >
      <div
        v-for="(review, index) in reviewArr"
        :key="index"
      >
        <meta itemprop="name" :content="review.title">
        <meta itemprop="author" :content="review.nickname">
        <meta itemprop="datePublished" :content="review.created_at">
        <meta itemprop="description" :content="review.detail">
      </div>
    </div>
    <div v-if="aggregateRating && reviewCount" itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating" class="hidden">
      <span v-if="aggregateRating" itemprop="ratingValue">{{ aggregateRating }}</span>
      <span v-if="reviewCount" itemprop="reviewCount">{{ reviewCount }}</span>
    </div>
    <template v-if="product.gtin">
      <meta itemprop="gtin" :content="product.gtin">
    </template>
    <template v-else>
      <meta itemprop="mpn" :content="product.mpn">
    </template>
    <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <meta itemprop="priceCurrency" content="PLN">
      <meta
        itemprop="price"
        :content="bundleMinPrice || parseFloat(product.price_incl_tax).toFixed(2)"
      >
      <meta
        itemprop="availability"
        :content="product.stock && product.stock.is_in_stock ? 'InStock' : 'OutOfStock'"
      >
      <meta
        itemprop="url"
        :content="'https://kultowy.pl/' + product.url_path"
      >
      <meta
        v-show="product.special_to_date"
        itemprop="priceValidUntil"
        :content="product.special_to_date"
      >
      <template v-if="product.gtin">
        <meta itemprop="gtin" :content="product.gtin">
      </template>
      <template v-else>
        <meta itemprop="mpn" :content="product.mpn">
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBundleOptionsMinPrices } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import { round } from 'mathjs'
import { isSpecialPriceActive } from '@vue-storefront/core/modules/catalog/helpers/taxCalc'

export default {
  name: 'ProductMeta',
  props: {
    product: {
      type: Object,
      required: true,
      default: null
    },
    gallery: {
      type: Array,
      required: true,
      default: null
    },
    aggregateRating: {
      type: Number,
      required: false,
      default: null
    },
    reviewCount: {
      type: Number,
      required: false,
      default: null
    },
    reviewArr: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    getBrand () {
      return this.getLabelForBrand()
    },
    ...mapGetters({
      attributesByCode: 'attribute/attributeListByCode'
    }),
    bundleSpecialMinPrice () { // bundle special min price
      const bundleSpecialPrice = this.product.original_special_price / 100 || 0
      return bundleSpecialPrice && isSpecialPriceActive(this.product.special_from_date, this.product.special_to_date)
        ? getBundleOptionsMinPrices(this.product, this.product.price_type).map(optionPrice => round(optionPrice * bundleSpecialPrice, 2)).reduce((memo, current) => memo + current, 0)
        : 0
    },
    bundleMinPrice () { // bundle min price
      return this.bundleSpecialMinPrice || getBundleOptionsMinPrices(this.product, this.product.price_type).reduce((memo, current) => memo + current, 0)
    }
  },
  methods: {
    getLabelForBrand () {
      if (this.attributesByCode && this.attributesByCode['brand']) {
        let attrOptions = this.attributesByCode.brand.options
          .filter(option => option.value === String(this.product.brand))
        if (attrOptions && attrOptions.length) {
          return attrOptions[0].label
        } else {
          return false
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
