<template>
  <div class="back-button" @click="goBack()">
    <i class="icon-Return cl-main" />
  </div>
</template>

<script>
export default {
  methods: {
    goBack () {
      if (this.to) {
        this.$router.push(this.to)
      } else {
        this.$router.back()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.back-button {
  font-size: 25px;
  // border: 2px solid #d2d2d2;
  // border-radius: 50px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  flex-shrink: 0;
  margin-right: 15px;
  color: $main;
}

.icon-arrow_left_grey:before {
  color: $main !important;
}

.back-button i {
  padding: 0;
  display: block;
}
</style>
