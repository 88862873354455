<template>
  <div class="relative">
    <div class="relative">
      <textarea
        class="
          mt10 px20 pb10 w-100 brdr-25px bg-cl-white border-box brdr-1
          brdr-cl-white h4 sans-serif
        "
        :class="{empty: value === ''}"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :value="value"
        :autofocus="autofocus"
        :ref="focus ? name : false"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('keyup.enter', $event.target.value)"
        @keyup="$emit('keyup', $event)"
      />
      <label>
        {{ placeholder }}
      </label>
    </div>

    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'BaseTextarea',
  components: {
    ValidationMessages
  },
  data () {
    return {
      iconActive: false,
      icon: 'visibility'
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';

  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-white: color(white);
  $color-main: color(main);
  $main-smoke: color(main-smoke);
  $color-forest-green: color(forest-green);
  $color-hover: color(tertiary, $colors-background);

  textarea {
    font: 400 14px 'Work Sans';
    &:hover,
    &:focus {
      outline: none;
      border-color: $color-main;
    }
    resize: none;
    background: $color-white;
    min-height: 100px;
  }

  label {
    color: #999;
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 15px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  textarea:not(.empty) {
    border-color: $color-main;
  }
  textarea:focus ~ label,
  textarea:not(.empty) ~ label {
    left: 20px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: $main-smoke;
    top: -5px;
    font-size: 14px;
    color: $color-main;
  }

  .icon {
    right: 6px;
    top: 10px;
    &:hover,
    &:focus {
      color: $color-hover;
    }
  }
</style>
