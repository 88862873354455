<template>
  <div>
    <div class="mt30 cl-black serif" v-if="!itemsPerPage || itemsPerPage.length === 0">
      {{ $t('No reviews have been posted yet. Please don\'t hesitate to share Your opinion and write the first review!') }}
    </div>
    <div class="mt30 bg-cl-white brdr-1 brdr-cl-matterhorn brdr-9px px30 py40 flex wrap row review-item" v-for="(item, index) in itemsPerPage" :key="index" itemprop="review" itemscope itemtype="http://schema.org/Review">
      <meta itemprop="itemReviewed" :content="productName | htmlDecode">
      <div class="col-xs-8 col-md-8 col-xl-2 p0 review-item__name">
        <span class="flex cl-black weight-600 mb20" itemprop="reviewAspect" :content="item.title">{{ item.nickname }}</span>
        <span v-if="item.customer_id !== null" class="flex brdr-bottom-1 brdr-cl-matterhorn h5 weight-900 cl-black my30 check-user review-item__recommend">{{ $t('Zweryfikowany klient') }}</span>
        <span v-if="isRecommendedProduct(item)" class="flex middle-xs h5 cl-black mb10 review-item__recommend"><i class="icon-favourite-fill pr5 cl-main" /> {{ $t('Polecam ten produkt') }}</span>
      </div>
      <div class="col-xs-12 col-md-12 col-xl review-item__stars">
        <div class="flex stars" v-if="item.ratings && item.ratings.length">
          <div v-for="(rating, indexRating) in item.ratings" :key="indexRating">
            <span v-if="rating.title === 'Value'" class="pointer flex">
              <i v-for="star in item.ratings[0].value" :key="star" class="cl-main h4 lh20">&#9733;</i>
            </span>
          </div>
        </div>
        <h4 class="weight-400 m0 fs-medium cl-black">
          {{ item.title }}
        </h4>
        <p class="cl-black fs-medium-small lh25" itemprop="reviewBody" :content="item.detail">
          {{ item.detail }}
        </p>
      </div>
      <div class="col-xs-4 col-md-4 col-xl-2 review-item__date">
        <p class="cl-black m0 fs-medium-small">
          {{ item.created_at | date('MM/DD/YYYY') }}
        </p>
      </div>
    </div>
    <div class="row middle-xs center-xs mt50" v-if="pageCount > 1">
      <a href="#" class="mr10 no-underline" :class="{ inactive: currentPage === 1 }" @click.prevent="prevPage">
        <i class="icon-arrow_left_black" />
      </a>
      <span class="mx10 pagination-page" v-for="pageNumber in pageList" :key="pageNumber">
        <span class="fs-medium block py15 px20 bg-cl-mine-shaft cl-white" v-if="pageNumber === currentPage">
          {{ pageNumber }}
        </span>
        <a href="#" class="fs-medium block py15 px20 bg-cl-secondary pointer" v-else @click.prevent="changePage(pageNumber)">
          {{ pageNumber }}
        </a>
      </span>
      <a href="#" class="ml10 no-underline" :class="{ inactive: currentPage === pageCount }" @click.prevent="nextPage">
        <i class="icon-arrow_right_black" />
      </a>
    </div>
    <!-- <script v-html="getJsonLd" type="application/ld+json" /> -->
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { reviewJsonLd } from '@vue-storefront/core/helpers'
export default {
  props: {
    perPage: {
      type: Number,
      required: false,
      default: 4
    },
    items: {
      type: Array,
      default: () => []
    },
    productName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    itemsPerPage () {
      let start = ((this.currentPage - 1) * this.perPage)
      let end = start + this.perPage
      return this.items.slice(start, end).filter(review => !!review.review_status)
    },
    pageCount () {
      return Math.floor(this.items.length / this.perPage) + Math.min(1, this.items.length % this.perPage)
    },
    pageList () {
      if (this.pageCount <= 5 || this.currentPage === 1 || this.currentPage === 2) {
        const pages = []
        for (let i = 1; i <= Math.min(this.pageCount, 5); i += 1) {
          pages.push(i)
        }
        return pages
      } else if (this.currentPage === this.pageCount || this.currentPage === this.pageCount - 1) {
        const pages = []
        for (let i = this.pageCount; i >= 1 && i >= this.pageCount - 4; i -= 1) {
          pages.unshift(i)
        }
        return pages
      } else {
        return [this.currentPage - 2, this.currentPage - 1, this.currentPage, this.currentPage + 1, this.currentPage + 2]
      }
    },
    storeView () {
      return currentStoreView()
    }
    // getJsonLd () {
    //   return reviewJsonLd(this.itemsPerPage, this.product, this.$store.state.storeView.i18n.currencyCode)
    // }
  },
  methods: {
    isRecommendedProduct (item) {
      if (item.ratings && item.ratings.length) {
        let recommend = item.ratings.filter(el => el.title === 'Recommend')
        if (recommend.length && recommend[0].value === 5) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    prevPage () {
      this.currentPage = Math.max(1, this.currentPage - 1)
    },
    nextPage () {
      this.currentPage = Math.min(this.pageCount, this.currentPage + 1)
    },
    changePage (pageNumber) {
      this.currentPage = Math.max(1, Math.min(this.pageCount, pageNumber))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$mine-shaft: color(mine-shaft);
$white: color(white);

.review-item {
  @media (max-width: 1024px) {
    padding: 20px;
  }
  @media (max-width: 1199px) {
    &__stars {
      order: 3;
    }
    &__name {
      order: 1;
    }
    &__date {
      order: 2;
    }
    &__recommend {
      font-size: 0.8em;
    }
  }
  .stars {
    margin-bottom: 25px;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
}
.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.check-user {
  width: fit-content;
  padding-bottom: 3px;
}

.pagination-page {
  a {
    &:hover {
      background-color: $mine-shaft;
      color: $white;
    }
  }
  @media (max-width: 767px) {
    margin: 0;
  }
  a, span {
    @media (max-width: 767px) {
      padding: 10px 12px;
      font-size: 16px;
    }
  }
}
</style>
