<template>
  <div class="breadcrumbs cl-black relative flex middle-xs" vocab="http://schema.org/" typeof="BreadcrumbList">
    <template v-if="!sidebarMenu">
      <back-button v-if="paths.length > 0 " class="back-button" />
      <span class="cl-black" v-for="(link, index) in paths" :key="link.route_link" property="itemListElement" typeof="ListItem">
        <router-link :to="link.route_link" class="cl-black" property="item" typeof="WebPage">
          <span property="name" :class="{'uppercase': link.name === 'Lego®' || link.name === 'Elle' || link.name === 'Elle Decoration' || link.name === 'Elle Man'}">
            <span class="hidden" v-if="breadcrumbsEmoji && (paths.length-1 === index)" v-html="breadcrumbsEmoji"/>
            {{ link.name | htmlDecode }}
            </span>
        </router-link>&nbsp;.&nbsp;
        <meta property="position" :content="index">
      </span>
    </template>
    <h1 class="weight-600 fs16 m0 inline" :class="{'menu-dot': sidebarMenu && current, 'cl-main': !sidebarMenu, 'uppercase': current === 'Lego®' || current === 'Elle' || current === 'Elle Decoration' || current === 'Elle Man'}">
      {{ $t(current) | htmlDecode }}
    </h1>
  </div>
</template>

<script>
import { Breadcrumbs } from '@vue-storefront/core/modules/breadcrumbs/components/Breadcrumbs.ts'
import BackButton from 'theme/components/core/BackButton';
import config from 'config'

export default {
  props: {
    sidebarMenu: {
      required: false,
      type: Boolean
    }
  },
  components: {
    BackButton
  },
  mixins: [Breadcrumbs],
  computed: {
    breadcrumbsEmoji () {
      return config.entities.category.breadcrumbsEmoji
    }
  }
}
</script>
<style lang="scss">
.inline {
  display: inline;
}
.display-inline {
  display: inline-block;
}
.back-button {
  display: inline-block;
  vertical-align: top;
}
 .hidden {
  display: none;
}
@media (max-width: 767px) {
  .back-button {
    float: left;
    margin-right: 10px;
    margin-left: 5px;
  }
  .menu-dot {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      height: 5px;
      background-color: #1396EC;
      border-radius: 50%;
      right: -10px;
      bottom: 6px;
    }
  }
}
</style>
